import React from 'react'
import styled from 'styled-components'
import Layout from 'components/layout'
import HeaderSpacer from 'components/layout/header/spacer'
import { Helmet } from 'react-helmet'
import { organizationSchema } from 'data/schema-data'
import { graphql, Link } from 'gatsby'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import { StickyContainer, Sticky } from 'react-sticky'
import { GatsbyImage } from 'gatsby-plugin-image'
import SocialShare from 'components/social/blog-social-share'

const BlogPostTemplate = props => {
  const {
    data: {
      sanityBlogPost: {
        author: authorList,
        authors,
        bodyText,
        category: categoryObj,
        excerpt,
        featuredImage,
        handle,
        html,
        modifiedDate,
        modifiedDateString,
        publishedDate,
        publishedDateString,
        relatedEquipment,
        subcategory: subcategoryObj,
        tags,
        title,
      },
      allSanityBlogPost: { nodes: blogPosts },
    },
    location,
  } = props
  const category = categoryObj ? categoryObj : null
  const subcategory = subcategoryObj ? subcategoryObj : null
  const tagsAndTopics = []
  if (category) {
    tagsAndTopics.push({
      label: category.titlePlural,
      link: category.slug,
    })
  }
  if (subcategory) {
    tagsAndTopics.push({
      label: subcategory.title,
      link: subcategory.slug,
    })
  }
  if (Array.isArray(tags) && tags.length > 0) {
    tags.forEach(tag => {
      tagsAndTopics.push({
        label: tag.titlePlural,
        link: tag.slug,
      })
    })
  }
  const relatedPosts = getRelatedPosts(handle, blogPosts)
  return (
    <Layout>
      <Helmet>
        <title>{title} | Hutson Inc</title>
        <meta name='description' content={excerpt} />
        {authors ? <meta name='author' content={authors} /> : null}
        <meta property='og:title' content={title} />
        <meta property='og:description' content={excerpt} />
        <meta property='og:type' content='article' />
        {Array.isArray(authorList)
          ? authorList.map(author => (
              <meta
                property='article:author'
                content={author.name}
                key={`meta-article-author-${author.name}`}
              />
            ))
          : null}
        {category ? <meta property='article:section' content={category.titlePlural} /> : null}
        <meta property='og:url' content={location.href} />
        <meta property='og:image' content={`${featuredImage.asset.url}`} />
        <meta property='twitter:card' content='summary' />
        <meta name='twitter:site' content='@Hutson_Inc' />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BlogPosting',
            'headline': title,
            'articleBody': bodyText,
            'image': `${featuredImage.asset.url}`,
            ...(category
              ? {
                  articleSection: category.titlePlural,
                }
              : {}),
            ...(Array.isArray(authorList) && authorList.length > 0
              ? {
                  author: authorList.map(author => ({
                    '@type': 'Person',
                    'name': author.name,
                  })),
                }
              : {}),
            'publisher': organizationSchema,
            ...(modifiedDate
              ? {
                  dateModified: modifiedDate,
                }
              : {}),
            ...(publishedDate
              ? {
                  dateCreated: publishedDate,
                  datePublished: publishedDate,
                }
              : {}),
            'url': location.href,
            'mainEntityOfPage': {
              '@type': 'WebPage',
              '@id': location.href,
            },
          })}
        </script>
      </Helmet>
      <HeroGrid>
        <ImageColumn>
          <GatsbyImage
            image={featuredImage.asset.gatsbyImageData}
            alt=''
            style={{ width: '100%', height: '100%', position: 'absolute' }}
            objectFit='cover'
            objectPosition='50% 50%'
          />
        </ImageColumn>
        <TitleColumn>
          <HeaderSpacer />
          <TitleContainer>
            {category ? (
              <CategoryLink to={category.slug}>{category.titlePlural}</CategoryLink>
            ) : (
              <CategoryLink to='/blog/'>Blog</CategoryLink>
            )}
            <Title>{title}</Title>
            {authors ? <Author>by {authors}</Author> : null}
          </TitleContainer>
        </TitleColumn>
      </HeroGrid>
      <Content>
        <PostColumn>
          <PostBody
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
          <PublishedDate>
            {modifiedDateString
              ? `Originally published ${publishedDateString}, updated ${modifiedDateString}`
              : `Published ${publishedDateString}`}
          </PublishedDate>
          {tagsAndTopics.length > 0 && (
            <TagsAndTopics>
              <TagsAndTopicsLabel>Tags and topics:</TagsAndTopicsLabel>
              <TagsAndTopicsList>
                {tagsAndTopics.map(t => (
                  <li key={t.label}>
                    <Link to={t.link}>{t.label}</Link>
                  </li>
                ))}
              </TagsAndTopicsList>
            </TagsAndTopics>
          )}
        </PostColumn>
        <SidebarColumn>
          {relatedPosts.length > 0 && (
            <>
              <SidebarHeader>Related Articles</SidebarHeader>
              <RelatedArticlesList>
                {relatedPosts.map(post => (
                  <li key={post.id}>
                    <Link to={post.slug}>{post.title}</Link>
                  </li>
                ))}
              </RelatedArticlesList>
            </>
          )}
          <SidebarStickyContainer>
            <Sticky>
              {({ style }) => (
                <SidebarStickyInner style={style}>
                  {relatedEquipment.length > 0 && (
                    <>
                      <SidebarHeader>Shop Related Equipment</SidebarHeader>
                      <RelatedEquipment relatedEquipment={relatedEquipment} />
                    </>
                  )}
                  <SidebarHeader>Share</SidebarHeader>
                  <SocialShare title={title} />
                </SidebarStickyInner>
              )}
            </Sticky>
          </SidebarStickyContainer>
        </SidebarColumn>
      </Content>
    </Layout>
  )
}

const HeroGrid = styled.div`
  background-color: ${props => props.theme.color.n10};

  @media (min-width: 900px) {
    ${clearfix}
    display: flex;
    flex-direction: row-reverse;
    min-height: 400px;
  }

  @media (min-width: 1024px) {
    min-height: 500px;
  }
`

const ImageColumn = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: 50%;
  position: relative;
  width: auto;

  @media (min-width: 900px) {
    height: auto;
    overflow: auto;
    padding-bottom: 0;
    ${column('5/12', 0)}
  }

  @media (min-width: 1024px) {
    ${column('7/12', 0)}
  }
`

const TitleColumn = styled.div`
  @media (min-width: 900px) {
    ${column('7/12', 0)}
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 1024px) {
    ${column('5/12', 0)}
  }
`

const TitleContainer = styled.div`
  padding: 36px 24px;

  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 64px;
  }
`

const CategoryLink = styled(Link)`
  color: ${props => props.theme.color.g400};
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;

  :hover,
  :focus {
    text-decoration: underline;
  }
`

const Title = styled.h1`
  color: ${props => props.theme.color.n900};
  font-size: 36px;
  font-weight: 600;
  padding: 0;
  margin: 16px 0 0;

  @media (min-width: 900px) {
    font-size: 48px;
  }
`

const Author = styled.span`
  color: ${props => props.theme.color.n800};
  display: inline-block;
  font-weight: 400;
  margin-top: 16px;
`

const Content = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  padding: 24px 24px 36px;

  @media (min-width: 900px) {
    ${clearfix}
    display: flex;
    padding: 64px 24px;
    width: 900px;
  }
`

const PostBody = styled.div`
  color: ${props => props.theme.color.n800};
  font-size: 18px;
  font-weight: 400;

  *:first-child {
    margin-top: 0;
  }

  a {
    color: #377539;
    font-weight: 600;
    text-decoration: none;
    vertical-align: bottom;
    text-decoration: underline;
  }

  p {
    font-size: 18px;
    line-height: 1.75;
    margin: 12px 0 18px;
  }

  strong {
    font-weight: 600;
    vertical-align: bottom;
  }

  blockquote {
    background-color: ${props => props.theme.color.n20};
    font-style: italic;
    margin: 16px 0;
    padding: 16px;

    > * {
      :last-child {
        margin-bottom: 0;
      }
    }
  }

  ul,
  ol {
    margin: 0 0 0 12px;

    li {
      font-size: 18px;
      line-height: 1.75;
      margin: 3px 0;
      padding: 0;

      a {
        color: #377539;
        text-decoration: none;
        vertical-align: bottom;
        text-decoration: underline;
      }

      p {
        margin: 0;
        padding: 0;
      }
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    max-width: none;
    text-align: left;
    text-transform: none;
  }

  h2 {
    font-size: 28px;
    margin: 24px 0 12px;
  }

  h3 {
    font-size: 24px;
    margin: 20px 0 12px;
  }

  h4 {
    font-size: 21px;
    margin: 18px 0 12px;
  }

  h5 {
    font-size: 20px;
    margin: 18px 0 12px;
  }

  h6 {
    font-size: 18px;
    margin: 18px 0 12px;
  }

  table {
    border: 1px solid #d7d7d7;
    border-collapse: collapse;
    display: inline-block;
    text-align: left;
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    width: auto;

    thead {
      font-weight: 600;

      tr {
        border-bottom: 1px solid #d7d7d7;
      }
    }

    tr {
      :not(:last-of-type) {
        border-bottom: 1px solid #d7d7d7;
      }
    }

    td,
    th {
      padding: 8px;

      :not(:last-of-type) {
        border-right: 1px solid #d7d7d7;
      }
    }
  }

  img {
    display: block;
    margin: 45px auto;
    max-width: 100%;

    + em {
      display: block;
      font-size: 14px;
      margin-top: -35px;
    }
  }

  .iframe {
    height: 0px;
    margin: 45px auto;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
  }

  iframe {
    position: absolute;
    height: 100%;
    left: 0px;
    top: 0px;
    width: 100%;
  }

  .grid {
    ${clearfix}
  }

  .grid-2-col {
    > * {
      @media (min-width: 600px) {
        ${column('1/2', 24)}
        ${clearfix}
      }
    }
  }
`

const PostColumn = styled.div`
  @media (min-width: 900px) {
    ${column('2/3')}
  }
`

const SidebarColumn = styled.div`
  margin-top: 48px;

  @media (min-width: 900px) {
    ${column('1/3')}
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }
`

const SidebarStickyContainer = styled(StickyContainer)`
  flex-grow: 2;
`

const PublishedDate = styled.span`
  color: ${props => props.theme.color.n100};
  display: inline-block;
  font-style: italic;
  margin-top: 24px;
`

const TagsAndTopics = styled.div`
  margin-top: 24px;
`

const TagsAndTopicsLabel = styled.span`
  color: ${props => props.theme.color.n100};
  display: inline-block;
  font-weight: 500;
  vertical-align: middle;
`

const TagsAndTopicsList = styled.ul`
  display: inline-block;
  list-style: none;
  line-height: 1.15;
  margin: 0 0 0 12px;
  padding: 0;
  vertical-align: middle;

  li {
    display: inline-block;
    line-height: 1.15;
    margin: 0;
    vertical-align: middle;

    :not(:last-child) {
      margin-right: 8px;
    }

    a {
      background-color: ${props => props.theme.color.n30};
      color: ${props => props.theme.color.n100};
      display: block;
      font-size: 14px;
      padding: 6px;
      text-decoration: none;

      :focus,
      :hover {
        background-color: ${props => props.theme.color.n40};
        color: ${props => props.theme.color.n200};
      }
    }
  }
`

const SidebarHeader = styled.h2`
  color: ${props => props.theme.color.n800};
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  margin: 0 0 16px;
`

const RelatedArticlesList = styled.ul`
  list-style: none;
  margin: 0 0 24px;
  padding: 0;

  li {
    border-bottom: 1px solid ${props => props.theme.color.n30};
    display: block;
    margin: 0;
    padding: 0;

    :first-child {
      border-top: 1px solid ${props => props.theme.color.n30};
    }

    a {
      color: ${props => props.theme.color.n100};
      display: block;
      line-height: 1.15;
      padding: 12px;
      text-decoration: none;

      :hover,
      :focus {
        text-decoration: underline;
      }
    }
  }
`

const SidebarStickyInner = styled.div`
  padding-top: 16px;
`

const RelatedEquipment = ({ relatedEquipment }) => {
  const related = relatedEquipment.map(obj => {
    const newObj = {
      ...obj,
      props: {},
    }
    if (obj.isInternal) {
      newObj.props.to = obj.slug
    } else {
      newObj.props.href = obj.link
      newObj.props.as = 'a'
    }
    return newObj
  })
  return (
    <RelatedEquipmentList>
      {related.map(equipment => (
        <li key={equipment.id}>
          <RelatedEquipmentLink {...equipment.props}>
            <GatsbyImage
              image={equipment.image.asset.gatsbyImageData}
              alt={equipment.title}
              objectFit='cover'
              objectPosition='50% 50%'
              style={{ width: '100%', height: '100%' }}
            />
            <RelatedEquipmentText>{getCallToAction(equipment)}</RelatedEquipmentText>
          </RelatedEquipmentLink>
        </li>
      ))}
    </RelatedEquipmentList>
  )
}

const RelatedEquipmentList = styled.ul`
  list-style: none;
  margin: 0 0 40px;
  padding: 0;

  li {
    display: block;
    margin: 0 0 16px;
    padding: 0;
  }
`

const RelatedEquipmentLink = styled(Link)`
  border: 1px solid ${props => props.theme.color.n30};
  color: ${props => props.theme.color.g400};
  display: block;
  padding: 20px;
  text-decoration: none;

  :hover,
  :focus {
    text-decoration: underline;
  }
`

const RelatedEquipmentText = styled.div`
  margin-top: 12px;
  text-align: center;
`

const getRelatedPosts = (handle, blogPosts) => {
  if (blogPosts.length === 1) {
    return []
  }
  const matchIndex = blogPosts.findIndex(post => post.handle === handle)
  const related = [
    ...blogPosts.slice(matchIndex + 1),
    ...blogPosts.slice(0, matchIndex).reverse(),
  ].slice(0, 3)
  return related
}

const getCallToAction = ({ callToAction, title }) => {
  switch (callToAction) {
    case 'LEARN_MORE':
      return `Learn more about ${title}`
    case 'COMPARE':
      return `Compare ${title}`
    case 'SHOP':
      return `Shop ${title}`
    case 'VIEW':
    default:
      return `View ${title}`
  }
}

export const pageQuery = graphql`
  query blogPostTemplate($category: String, $handle: String!) {
    sanityBlogPost(handle: { eq: $handle }) {
      author {
        name
      }
      authors
      bodyText
      category {
        slug
        titlePlural
      }
      excerpt
      featuredImage {
        asset {
          gatsbyImageData(width: 800)
          url
        }
      }
      handle
      html
      modifiedDate
      modifiedDateString
      publishedDate
      publishedDateString
      relatedEquipment {
        callToAction
        id
        image {
          asset {
            gatsbyImageData(width: 800)
          }
        }
        isInternal
        link
        title
        slug
      }
      subcategory {
        slug
        title
      }
      tags {
        slug
        titlePlural
      }
      title
    }
    allSanityBlogPost(
      sort: { fields: publishedDate, order: ASC }
      filter: { category: { titlePlural: { eq: $category } }, publishedDate: { ne: null } }
    ) {
      nodes {
        id
        handle
        slug
        title
      }
    }
  }
`

export default BlogPostTemplate
